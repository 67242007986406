import React from 'react';

import loadable from '@loadable/component';

const DumbComponent = loadable(() => import('../../subPages'));

export const Listing = ({ data }) => (
    //
    <DumbComponent heading={data.heading} intro={data.intro} childNodes={data.items} nodeType={data.nodeType} />
);
