import React from 'react';

import loadable from '@loadable/component';

const DumbComponent = loadable(() => import('../../subPages'));

export const SubPages = ({ data }) => (
    //
    <DumbComponent {...data} />
);
