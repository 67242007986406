import React, { FC } from 'react';
import styled from 'styled-components';
import { Box, Heading, Paragraph } from 'grommet';

interface props {
    heading: string;
    intro?: string;
    level?: 2 | '1' | '2' | '3' | '4' | '5' | '6' | 1 | 3 | 4 | 5 | 6 | undefined;
}

const Intro: FC<props> = ({ heading, intro, level = 2 }) => {
    if (!heading) {
        return null;
    }
    return (
        <Box justify="center" align="center" width={{ max: '960px' }} alignSelf="center">
            {heading && (
                <Heading level={level} color="accent-2" textAlign="center">
                    {heading}
                </Heading>
            )}
            {intro && (
                <Paragraph textAlign="center" fill>
                    {intro}
                </Paragraph>
            )}
        </Box>
    );
};

export default Intro;
