import React, { FC, useContext } from 'react';
import { Box, Heading, ResponsiveContext, Text } from 'grommet';
import styled, { css } from 'styled-components';

import { GatsbyImage } from 'gatsby-plugin-image';
import HtmlContent from './htmlContent';
import { createMarkup } from '../utils';
import Block from './block';

interface Props {
    title: string;
    subHeading?: string;
    standoutHeading?: boolean;
    intro?: string;
    image?: any;
    widget?: any;
}

const StyledH1 = styled(Heading)`
    ${({ theme }) => css`
        margin-top: 0;

        span {
            color: ${theme.global.colors.brand.light};
        }
    `}
`;

const StyledPageHeading = styled(Box)`
    ${({ theme, standoutHeading }) => css`
    position: relative;
    z-index: 10;
    ${
        standoutHeading &&
        css`
            @media (min-width: ${theme.global.breakpoints.medium.value}px) {
                min-height: 400px;
            }
        `
    }
       

        .hero-image {
          
                max-width: 300px;
                width: 100%;
                display: block;
                transform: rotate(5deg);
                position: relative;
                margin: 0 auto;

                @media (min-width: ${theme.global.breakpoints.medium.value}px) {
                    max-width: 400px;
                    right: 18px;
                    bottom: -33px;
                    position: absolute;
                    margin: 0;
                }
                @media (min-width: ${theme.global.breakpoints.large.value}px) {
                    max-width: 540px;
                    right: 18px;
                    bottom: -33px;
                    position: absolute;
                    margin: 0;
                }

                img {
                    display: block;
                    max-width: 100%;
                    width: 100%;
                    height: auto;
                }
            
  }
        }
        `}
`;

const PageHeading: FC<Props> = ({ title, subHeading, standoutHeading, intro, image, widget }) => {
    const size = useContext(ResponsiveContext);

    const align = size === 'large' ? 'left' : 'center';
    const alignSelf = size === 'large' ? 'start' : 'center';

    const headerWidget = widget || null;

    const MainBlock = () => (
        <>
            <Block
                direction="column"
                vertical={size === 'small' || size === 'medium' ? 'large' : 'xlarge'}
                background="brand"
            >
                <Box
                    width={{ max: '600px' }}
                    style={{ textAlign: standoutHeading ? align : 'center' }}
                    align={standoutHeading ? alignSelf : 'center'}
                    alignSelf={standoutHeading ? alignSelf : 'center'}
                    round="medium"
                >
                    <StyledH1 level={1} color="white" margin={{ bottom: subHeading || intro ? 'medium' : 'none' }}>
                        {title}
                    </StyledH1>
                    {subHeading && (
                        <StyledH1 level="2" color="white">
                            {subHeading}
                        </StyledH1>
                    )}

                    {intro && (
                        <Text color="white" size="large">
                            <HtmlContent dangerouslySetInnerHTML={createMarkup(intro)} />
                        </Text>
                    )}
                    {headerWidget && <>{headerWidget}</>}
                </Box>
            </Block>
            {standoutHeading && image && (
                <div className="hero-image">
                    <GatsbyImage image={image.localFile.childImageSharp.gatsbyImageData} alt={image.altText || ''} />
                </div>
            )}
        </>
    );

    return (
        <StyledPageHeading
            standoutHeading={standoutHeading}
            justify="center"
            className={standoutHeading ? 'page-heading standout' : 'light-1 page-heading'}
            background="brand"
            margin={{ bottom: 'large' }}
        >
            <MainBlock />
        </StyledPageHeading>
    );
};
export default PageHeading;
