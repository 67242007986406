import React from 'react';
import { graphql } from 'gatsby';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import ComponentRenderer from '../components/componentRenderer/ComponentRenderer';
import PageHeading from '../components/pageHeading';

const Page = (props) => {
    const {
        data: { wpPage },
        pageContext,
    } = props;
    const { contentItems, seo, title, children: childNodes, nodeType } = wpPage;

    return (
        <>
            <Seo post={wpPage} />
            {/* <pre>{JSON.stringify(contentItems.pageContent, null, 2)}</pre> */}

            <PageHeading
                title={title}
                subHeading={contentItems.subHeading}
                intro={contentItems.intro}
                standoutHeading={contentItems.standoutHeading}
                image={contentItems.image}
            />
            <ComponentRenderer
                components={contentItems.pageContent}
                nodeID={pageContext.id}
                childNodes={childNodes}
                nodeType={nodeType}
            />
        </>
    );
};

export default Page;

export const pageQuery = graphql`
    query GET_PAGE($id: String!) {
        wpPage(id: { eq: $id }) {
            nodeType
            title
            uri
            ...SeoPageInformation
            children {
                ... on WpPage {
                    ...PageEntryFragment
                }
            }
            contentItems {
                hideContactForm
                pageContent {
                    ... on WpPage_Contentitems_PageContent_Cta {
                        buttonText
                        content
                        heading
                        intro
                        linkOrPopup
                        link {
                            ... on WpPage {
                                id
                                uri
                                slug
                            }
                        }
                        style
                        fieldGroupName
                    }
                    ... on WpPage_Contentitems_PageContent_GalleryTextBlock {
                        buttonText
                        content
                        style
                        position
                        linkOrPopup
                        tag
                        link {
                            ... on WpPage {
                                id
                                uri
                                title
                                slug
                            }
                        }
                        intro
                        heading
                        fieldGroupName
                        gallery {
                            mediaItemUrl
                            sourceUrl
                            altText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(width: 400, height: 400, quality: 90, layout: CONSTRAINED)
                                }
                            }
                        }
                    }
                    ... on WpPage_Contentitems_PageContent_ImageTextBlock {
                        position
                        buttonText
                        content
                        heading
                        intro
                        tag
                        contentClass
                        linkOrPopup
                        link {
                            ... on WpPage {
                                id
                                uri
                                slug
                            }
                        }
                        image {
                            sourceUrl
                            altText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(width: 500, quality: 90, layout: CONSTRAINED)
                                }
                            }
                            mediaItemUrl
                        }
                        imageText
                        imageLink {
                            ... on WpTestimonial {
                                id
                                uri
                            }
                            ... on WpPage {
                                id
                                uri
                            }
                        }
                        position
                        style
                        fieldGroupName
                    }
                    ... on WpPage_Contentitems_PageContent_Quote {
                        author
                        quoteText
                        image {
                            sourceUrl
                            altText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(width: 400, quality: 90, layout: CONSTRAINED)
                                }
                            }
                            mediaItemUrl
                        }
                        fieldGroupName
                    }
                    ... on WpPage_Contentitems_PageContent_ContentBlock {
                        content
                        fieldGroupName
                        heading
                        subHeading
                        contentClass
                    }
                    ... on WpPage_Contentitems_PageContent_ListBlock {
                        fieldGroupName
                        heading
                        intro
                        items {
                            fieldGroupName
                            item
                        }
                    }
                    ... on WpPage_Contentitems_PageContent_Accordion {
                        fieldGroupName
                        heading
                        intro
                        isFaq
                        items {
                            fieldGroupName
                            item
                            itemHeading
                        }
                    }
                    ... on WpPage_Contentitems_PageContent_LogoWall {
                        fieldGroupName
                        heading
                        items {
                            sourceUrl
                            altText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(width: 180, quality: 90, layout: FIXED)
                                }
                            }
                            mediaItemUrl
                        }
                    }
                    ... on WpPage_Contentitems_PageContent_Downloads {
                        fieldGroupName
                        heading
                        intro
                        items {
                            title
                            fieldGroupName
                            file {
                                sourceUrl
                                mediaItemUrl
                            }
                        }
                    }
                    ... on WpPage_Contentitems_PageContent_StandoutImage {
                        fieldGroupName
                        caption
                        image {
                            mediaItemUrl
                            sourceUrl
                            altText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(width: 1000, quality: 90, layout: CONSTRAINED)
                                }
                            }
                        }
                    }
                    ... on WpPage_Contentitems_PageContent_VideoBlock {
                        fieldGroupName
                        embed
                        heading
                        intro
                    }
                    ... on WpPage_Contentitems_PageContent_Gallery {
                        fieldGroupName
                        heading
                        intro
                        gallery {
                            mediaItemUrl
                            sourceUrl
                            altText
                            localFile {
                                childImageSharp {
                                    thumb: gatsbyImageData(width: 400, height: 320, quality: 90, layout: CONSTRAINED)
                                    full: gatsbyImageData(quality: 90, layout: FULL_WIDTH)
                                }
                            }
                        }
                    }
                    ... on WpPage_Contentitems_PageContent_Radio {
                        fieldGroupName
                        heading
                        intro
                        image {
                            mediaItemUrl
                            sourceUrl
                            altText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(
                                        width: 200
                                        quality: 90
                                        layout: CONSTRAINED
                                        placeholder: TRACED_SVG
                                    )
                                }
                            }
                        }
                        audio {
                            sourceUrl
                            mediaItemUrl
                            localFile {
                                publicURL
                                relativePath
                            }
                        }
                    }
                    ... on WpPage_Contentitems_PageContent_SubPages {
                        fieldGroupName
                        heading
                        intro
                    }
                    ... on WpPage_Contentitems_PageContent_Listing {
                        heading
                        intro
                        items {
                            ... on WpPage {
                                ...PageEntryFragment
                            }
                        }
                        fieldGroupName
                    }
                    ... on WpPage_Contentitems_PageContent_FullQuote {
                        fieldGroupName
                    }
                    ... on WpPage_Contentitems_PageContent_Banner {
                        fieldGroupName
                        useCustom
                        link {
                            ... on WpPage {
                                id
                                uri
                                slug
                            }
                        }
                        small {
                            mediaItemUrl
                            sourceUrl
                            altText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(width: 390, quality: 90, layout: CONSTRAINED)
                                }
                            }
                        }
                        large {
                            mediaItemUrl
                            sourceUrl
                            altText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(width: 1195, quality: 90, layout: CONSTRAINED)
                                }
                            }
                        }
                    }
                    ... on WpPage_Contentitems_PageContent_ContactSelect {
                        fieldGroupName
                        heading
                        style
                        intro
                        afterText
                        blocks {
                            link {
                                ... on WpPage {
                                    id
                                    slug
                                    uri
                                }
                            }
                            fieldGroupName
                            linkOrPopup
                            content
                            buttonText
                        }
                    }
                }
                subHeading
                standoutHeading
                intro
                image {
                    sourceUrl
                    altText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(width: 540, quality: 90, layout: CONSTRAINED)
                        }
                    }
                    mediaItemUrl
                }
            }
        }
    }
`;
